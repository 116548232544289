<template>
    <Overlay v-if="isLoading" />
    <iframe v-show="currentSrc" :ref="setIframeValue" :src="currentSrc" @load="handleLoaded" :class="class" v-bind="$attrs"
        :key="currentSrc"></iframe>
</template>

<script lang="ts">
export interface IframeProps {
    /**
    * URL of the page to embed 
    */
    src: string,
    /**
    * Enable loading the iframe. By default is true, when set to false will stop iframe reloads until it is enabled again
    */
    enabled?: boolean,
    class?: string,
    syncContexts?: boolean,
    useLoadingMessage?: boolean,
};
</script>

<script setup lang="ts">
import Overlay from './Overlay.vue';
import { ref, watch, onActivated, onUnmounted } from 'vue';
import { GlobalMessenger as messenger, context } from 'o365-modules';
import { useEventListener } from 'o365-vue-utils';

const props = withDefaults(defineProps<IframeProps>(), {
    class: 'w-100 h-100',
    enabled: true,
    syncContexts: false,
});

const emit = defineEmits<{
    (e: 'load', event: Event, currentSrc: string | null): void
}>();

//const iframeRef = ref(null);
const iframeRef = ref<HTMLIFrameElement>();
function setIframeValue(newEl: HTMLIFrameElement | undefined) {
    iframeRef.value = newEl;
}
const currentSrc = ref<string | null>(null);
const isLoading = ref(!!props.src);

watch(() => props.src, (newSrc, oldSrc) => {
    if (!props.enabled || newSrc === oldSrc) {
        return;
    }

    setCurrentSrc(newSrc);
});
watch(() => props.enabled, (isEnabled) => {
    if (isEnabled && props.src !== currentSrc.value) {
        setCurrentSrc(props.src);
    }
});

let prevChangeEvent: (() => void) | null = null;
let event = {}
function handleLoaded(e: Event) {
    event = e;
    if (props.syncContexts) {
        if (prevChangeEvent) {
            prevChangeEvent();
            prevChangeEvent = null;
        }
    }

    if (!props.useLoadingMessage) {
        isLoading.value = false;
        emit('load', e, currentSrc.value);
    }
}

let cancelContextChange = null;
if (props.syncContexts) {
    cancelContextChange = context.on('Change', (pContext: any) => {
        post({ event: 'ContextChange', id: pContext.id })
    });
}

function setCurrentSrc(newSrc: string) {
    if (newSrc !== undefined && !newSrc.includes('undefined')) {
        if (newSrc && currentSrc.value !== newSrc) { isLoading.value = true; }
        currentSrc.value = newSrc;
    } else {
        currentSrc.value = null;
    }
}

onActivated(() => {
    setCurrentSrc(props.src);
});

if (props.enabled && props.src !== currentSrc.value) {
    setCurrentSrc(props.src);
}

if (props.useLoadingMessage ) {
    useEventListener(window, 'message', (pEvent) => {

        const data = pEvent.data;

        if (iframeRef.value == null || data == null || typeof data !== 'object' || data.type !== 'o365-system' || data.action !== 'app-created') {
            return;
        }
        if (iframeRef.value.contentWindow == pEvent.source) {
            isLoading.value = false;
            emit('load', event, currentSrc.value);
            return;
        }
        // 
    });
}

/**
 * Attempt to send a message to the iframe.
 */
function post<T>(pMessage: T) {
    if (iframeRef.value?.contentWindow) {
        const message = messenger.payloadToMessage(pMessage);
        iframeRef.value.contentWindow.postMessage(message);
    }
}

onUnmounted(() => {
    if (cancelContextChange) {
        cancelContextChange()
    }
})

defineExpose({ iframeRef, post, currentSrc, isLoading });
</script>